<template>
  <div class="search-form-wrapper">
    <div class="search-form">
      <p>Enter an existing tracking number to see the status of the tack:</p>
      <br />
      <label for="tracking-number">Tracking number</label>
      <input type="text" v-model="trackingNumber" name="trackingNumber" id="tracking-number" v-on:keyup.enter="searchTack" />
      <button @click="searchTack" class="tack-search-button">SEARCH</button>
    </div>

    <div v-if="isFound && searchPerformed" class="tack-search-result-wrapper">
      <div class="tack-search-item-image-wrapper">
        <img :src="tackImage" />
      </div>
      <div class="tack-search-item-details-wrapper">
        <span class="tack-search-item-details-detail-wrapper">
          <span class="tack-search-item-details-detail-title">TRACKING NUMBER</span>
          <span class="tack-search-item-details-detail-value">{{ tackNumber }}</span>
        </span>

        <span class="tack-search-item-details-detail-wrapper">
          <span class="tack-search-item-details-detail-title">NAME</span>
          <span class="tack-search-item-details-detail-value">{{ tackName }}</span>
        </span>

        <span class="tack-search-item-details-detail-wrapper">
          <span class="tack-search-item-details-detail-title">BRAND</span>
          <span class="tack-search-item-details-detail-value">{{ tackBrand }}</span>
        </span>

        <span class="tack-search-item-details-detail-wrapper">
          <span class="tack-search-item-details-detail-title">STATUS</span>
          <span class="tack-search-item-details-detail-value" style="padding: 0px">
            <span class="tack-search-item-details-status" :class="{statusIsStolen: isStolen}">{{ tackStatus }}</span>
          </span>
        </span>
      </div>
    </div>

    <div v-else-if="!isFound && searchPerformed" class="tack-search-result-not-found-wrapper">
      <h1 class="not-found">Not found</h1>
      <p>This tracking number either doesn't exist or is incomplete.</p>
    </div>

    <div class="tack-search-legend">
      <h1>RESULT KEY</h1>
      <p><strong>ACTIVE:</strong> The tack is active in our system and safe for you to purchase.</p>
      <p><strong>PENDING TRANSFER:</strong> The tack is pending transfer to a new owner.</p>
      <p>
        <strong>STOLEN:</strong> The owner has marked this tack as stolen. Do not purchase this tack.
        <a href="https://tacktrack.com.au/support/">Click here for help.</a>
      </p>
    </div>
  </div>
</template>

<script>
import {appConfig} from '@/config';
import tackSearchNoImage from '@/assets/images/tack-search-results-no-image.jpg';
import {getImageURL, getStatusLabel, isStolen} from '@/helpers';

const API_BASE_URL = appConfig.apiBaseUrl;

export default {
  name: 'SearchForm',
  props: {
    default: () => [],
  },
  data: function () {
    return {
      tackSearchNoImage: tackSearchNoImage,
      searchPerformed: false,
      isFound: false,
      isStolen: false,
      tackNumber: '',
      tackName: '',
      tackSize: '',
      tackBrand: '',
      tackColor: '',
      tackStatus: '',
      tackType: '',
      tackDescription: '',
      tackImage: '',
      trackingNumber: '',
      tack: null,
    };
  },
  watch: {
    trackingNumber() {
      this.trackingNumber = this.trackingNumber.toUpperCase();
    },
    tack() {
      if (this.tack) {
        this.isFound = true;
        this.tackNumber = this.tack.tackNumber;
        this.tackName = this.tack.tackName;
        this.tackBrand = this.tack.tackBrand;
        this.tackImage = getImageURL(this.tack.tackOwnerId, this.tack.tackImage);
        this.tackStatus = getStatusLabel(this.tack.tackStatus);
        this.isStolen = isStolen(this.tack.tackStatus);
      } else {
        this.isFound = false;
      }
    },
  },
  methods: {
    async searchTack() {
      this.searchPerformed = true;

      const res = await fetch(API_BASE_URL + '/tack/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          tackNumber: this.trackingNumber,
        }),
      });
      const tack = await res.json();

      if (tack.status === false) {
        this.tack = null;
      } else {
        this.tack = tack.data;
      }
    },
  },
};
</script>

<style scoped>
.search-form-wrapper {
  padding-bottom: 250px;
}
.search-form label {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  color: #0d123d;
  font-size: 26px;
}

#tracking-number {
  border: 2px solid #171d64;
  border-radius: 0px;
  height: 50px;
  padding: 5px 10px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  color: #171d64;
  font-size: 28px;
  margin: 0px 50px;
  max-width: 615px;
  text-transform: uppercase;
}

@media screen and (max-width: 500px) {
  #tracking-number {
    margin: 0;
  }
}

.tack-search-button {
  cursor: pointer;
  color: #ffffff;
  background-color: #171d64;
  border: none;
  font-size: 24px;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  padding: 12px 30px;
}

.tack-search-button:hover {
  color: #ffffff;
  background-color: #c5901b;
}

.tack-search-item-details-status {
  display: inline-block;
  background-color: #c49448;
  color: #ffffff;
  padding: 10px 20px;
  text-transform: uppercase;
  text-align: center;
  font-size: 23px;
}

.statusIsStolen {
  background-color: #ed2024 !important;
}

h1.not-found {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  color: #c49449;
  font-size: 50px;
  text-transform: uppercase;
}

.tack-search-legend {
  color: #0f123d;
  background: #dddee8;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 50px;
  right: 50px;
}

@media screen and (max-width: 780px) {
  .tack-search-legend {
    left: 20px;
    right: 20px;
  }
}

.tack-search-legend h1 {
  font-size: 20px;
  color: #11175d;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  margin-bottom: 20px;
}

.tack-search-legend a {
  color: #0f123d;
  text-decoration: none;
  font-weight: bold;
}

.tack-search-result-wrapper {
  background: #ffffff;
  padding: 20px;
  margin: 30px 0px 0px 0px;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
}

.tack-search-result-not-found-wrapper {
  min-height: 170px;
  background: #dddee8;
  padding: 20px;
  margin-top: 30px;
}

.tack-search-item-image-wrapper {
  flex: 1;
}

.tack-search-item-image-wrapper img {
  width: 312px;
  height: 208px;
}

.tack-search-item-details-wrapper {
  flex: 2;
}

.tack-search-item-details-detail-wrapper {
  display: flex;
  justify-content: flex-start;
}

.tack-search-item-details-detail-title {
  font-weight: 700;
  color: #12185d;
  flex: 1;
  padding: 15px 0px 15px 0px;
}

.tack-search-item-details-detail-value {
  flex: 3;
  padding: 15px 0px 15px 0px;
}
</style>
