import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import {useUserStore} from '../stores/UserStore';

// eslint-disable-next-line
import {authGuard} from '@/middlewares/authGuard';

import SearchView from '@/views/SearchView.vue';
import LoginView from '@/views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';
import PasswordResetView from '@/views/PasswordResetView.vue';
import NewPasswordView from '@/views/NewPasswordView.vue';
import AccountView from '@/views/AccountView.vue';
import TackRegisterView from '@/views/TackRegisterView.vue';
import TackEditView from '@/views/TackEditView.vue';
import TackTransferView from '@/views/TackTransferView.vue';
import ProfileEditView from '@/views/ProfileEditView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Entrance',
    component: SearchView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'Edit Profile',
    component: ProfileEditView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/regtack',
    name: 'Register Tack',
    component: TackRegisterView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/edit-tack/:id',
    name: 'Edit Tack',
    component: TackEditView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transfer-tack/:id',
    name: 'Transfer Tack',
    component: TackTransferView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login/:restrictedAccess',
    name: 'Login',
    component: LoginView,
    props: true,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordResetView,
  },
  {
    path: '/new-password/:token',
    name: 'NewPassword',
    component: NewPasswordView,
    props: true,
  },
  /*
  {
    path: "/:catchAll(.*)",
    name: "Not Found",
    component: NotFoundView,
  },
  */
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next): Promise<void> => {
  const userStore = useUserStore();
  await userStore.check();

  if (to.meta.requiresAuth && !userStore.isLoggedIn()) {
    next({path: '/login/1'});
  } else {
    next();
  }
});

export default router;
